import React, { useEffect, useState, createContext } from 'react';
import { Container } from 'react-bootstrap';
import { Routes, Route, useNavigate } from "react-router-dom";
import { NavbarComponent } from './components/nav';

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/index.css'
import { HomeComponent } from './pages/home';
import { PuzzleComponent } from './components/puzzle';
import { LoginComponent } from "./components/authentication";
import { tokenAuth, User } from "./api/user";
import { RulesComponent } from "./pages/rules";
import { AboutComponent } from "./pages/about";
import { UserProfileComponent } from "./pages/userProfile";
import { MultiplayerComponent } from "./components/multiplayer";
import { decodeJson, encodeJson } from "./api/serialization";
import { logMessage } from "./api/sockets";
import withCookies from 'react-cookie/cjs/withCookies';
import useCookies from 'react-cookie/cjs/useCookies';

const COOKIE_DATA = {
    path: "/"
}

export const UserContext = createContext<{
    user: User | null,
    setUser: React.Dispatch<React.SetStateAction<User | null>>,
    reauthenticate: () => void,
}>({
    user: null,
    setUser: () => { },
    reauthenticate: () => { }
});

function PirateScrabbleApp(): React.JSX.Element {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [user, setUser] = useState<User | null>(() => {
        const cookieData = cookies.user;
        if (!cookieData) return null;
        return decodeJson<User>(decodeURIComponent(cookieData));
    });

    const navigate = useNavigate();

    function logIn(user: User) {
        tokenAuth(user.token,
            (user: User) => {
                setCookie('user', encodeURIComponent(encodeJson(user)), COOKIE_DATA);
                setUser(user);
                navigate("/");
            },
            (error: string) => {
                logMessage(error);
                logOut();
            }
        );
    }

    function reauthenticate() {
        if (!user) return;
        tokenAuth(user.token,
            (user: User) => {
                setUser(user);
                setCookie('user', encodeURIComponent(encodeJson(user)), COOKIE_DATA);
            },
            (error: string) => {
                logMessage(`Error re-authenticating: ${error}`);
            }
        );
    }

    function logOut() {
        removeCookie("user", COOKIE_DATA);
        setUser((_) => null);
        logMessage(document.cookie)
    }

    return <UserContext.Provider
        value={{ user: user, setUser: setUser, reauthenticate: reauthenticate }}
    >
        <NavbarComponent logoutHandler={logOut} />
        <Container>
            <Routes>
                <Route path="/" element={<HomeComponent />} />
                <Route path="/about/" element={<AboutComponent />} />
                <Route path="/puzzles/" element={<PuzzleComponent />} />
                <Route path="/multiplayer/:gameId" element={<MultiplayerComponent />} />
                <Route path="/multiplayer/" element={<MultiplayerComponent />} />
                <Route path="/profile/:username" element={<UserProfileComponent />} />
                <Route path="/rules/" element={<RulesComponent />} />
                <Route path="/login/" element={<LoginComponent logInHandler={logIn} />} />
            </Routes>
        </Container>
    </UserContext.Provider >
}

export const App = withCookies(PirateScrabbleApp);

