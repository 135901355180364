import { Button, Col, Row } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    User,
    UserLoginRequest,
    UserResponse,
    UserCreationRequest,
    usernamePasswordAuth
} from "../api/user";
import { APISocket } from "../api/sockets";
import { decodeJson, encodeJson } from "../api/serialization";

export function LoginComponent(props: { logInHandler: (data: User) => void }) {
    const [currentInputValues, setCurrentInputValues] = useState({
        logInUsername: "",
        logInPassword: "",
        logInMessage: "",

        signUpUsername: "",
        signUpPassword: "",
        signUpMessage: "",
    })

    const handleLoginSubmit = (): void => {
        usernamePasswordAuth(
            currentInputValues.logInUsername,
            currentInputValues.logInPassword,
            (user: User) => {
                setCurrentInputValues(prevState => {
                    return { ...prevState, logInMessage: "Logging you in..." }
                })
                props.logInHandler(user);
            },
            (error: string) => {
                setCurrentInputValues(prevState => {
                    return { ...prevState, logInMessage: error }
                })
            }
        );
    };

    const handleCreateAccountSubmit = (): void => {
        const socket = new APISocket("/account/create");
        socket.bind(
            (_) => {
                const request: UserCreationRequest = {
                    username: currentInputValues.signUpUsername,
                    password: currentInputValues.signUpPassword,
                    email: "foobar"
                }
                socket.sendString(encodeJson(request));
            },
            (event: MessageEvent) => {
                const response = decodeJson<UserResponse>(event.data);
                if (response === null) return;
                if (!response.ok) {
                    setCurrentInputValues(prevState => {
                        return { ...prevState, signUpMessage: response.error }
                    });
                    return;
                }
                if (response.user === null) return;

                setCurrentInputValues(prevState => {
                    return { ...prevState, signUpMessage: `Created account for ${response.user?.username}!` }
                });
            },
        );
    };

    return <Row>
        <Col>
            <h3>Log in</h3>
            <form onSubmit={(event) => {
                event.preventDefault();
                handleLoginSubmit();
            }}>
                <input
                    type="text"
                    className="form-control"
                    required
                    placeholder="username"
                    onChange={(event) => {
                        setCurrentInputValues(prevState => {
                            return { ...prevState, logInUsername: event.target.value }
                        })
                    }} />
                <input
                    type="password"
                    className="form-control"
                    required
                    placeholder="password"
                    onChange={(event) => {
                        setCurrentInputValues(prevState => {
                            return { ...prevState, logInPassword: event.target.value }
                        })
                    }} />
                <Button type="submit">Log in</Button>
            </form>
            <div>{currentInputValues.logInMessage}</div>
        </Col>
        <Col>
            <h3>Create account</h3>
            <form onSubmit={(event) => {
                event.preventDefault();
                handleCreateAccountSubmit();
            }}>
                <input
                    type="text"
                    className="form-control"
                    required
                    placeholder="username"
                    onChange={(event) => {
                        setCurrentInputValues(prevState => {
                            return { ...prevState, signUpUsername: event.target.value }
                        })
                    }} />
                <input
                    type="password"
                    className="form-control"
                    required
                    placeholder="password"
                    onChange={(event) => {
                        setCurrentInputValues(prevState => {
                            return { ...prevState, signUpPassword: event.target.value }
                        })
                    }} />
                <Button type="submit">Create account</Button>
            </form>
            <p>{currentInputValues.signUpMessage}</p>
            <p style={{ fontStyle: "italic" }}>
                Site is in pre-release stage. Expect things to not work,
                and even for your account to be deleted without notice.
            </p>
        </Col>
    </Row>;
}
