import React, { memo } from "react";
import { Button, Card } from "react-bootstrap";
import { LinkContainer } from "../../pages/home";
import { Word } from "../../types";
import { SortablePlayerWords } from "./word";

interface PlayerWordsProps {
    title: string,
    words: Array<Word>,
    id: string
}

export function PlayerWords(props: PlayerWordsProps): React.JSX.Element {
    return <Card style={{ borderTopLeftRadius: "0" }}>
        <Card.Header>{props.title}</Card.Header>
        <Card.Body style={{
            overflowX: "clip",
            overflowY: "scroll",
            maxHeight: "22rem",
        }}>
            <SortablePlayerWords id={props.id} words={props.words} />
            <div style={{ width: "400px" }}></div>
        </Card.Body>
    </Card>
}

const playerWordsEqual = (prevProps: PlayerWordsProps, nextProps: PlayerWordsProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}

export const MemoPlayerWords = memo(PlayerWords, playerWordsEqual);
