/**
 * Returns index of object in array with given id, given each object has an "id" field.
 */
export function indexFromIdFieldArray<T extends { id: string }>(array: Array<T>, id: string) {
    return array.findIndex(object => object.id === id);
}

/**
 * Returns value of object in array with given id
 */
export function valueFromIdFieldArray<T extends { id: string }>(array: Array<T>, id: string): T {
    return array[indexFromIdFieldArray(array, id)];
}
