const SOCKET_BASE_URL: string = "wss://api.playpiratescrabble.com/ws";
export const COOKIE_DATA: string = " SameSite=None; Secure; domain=.playpiratescrabble.com"
export const LOG_LEVEL: "DEBUG" | "PROD" = "DEBUG";


export function logMessage(message: any) {
    if (LOG_LEVEL === "DEBUG") {
        console.log(message);
    }
}

export class APISocket {
    readonly socket: WebSocket;
    readonly name: string;

    constructor(relativeEndpoint: string, socketName: string = "", closeHandler: null | ((event: CloseEvent) => void) = null) {
        const endpoint: string = SOCKET_BASE_URL + relativeEndpoint;
        this.socket = new WebSocket(endpoint);

        if (socketName === "") {
            this.name = relativeEndpoint;
            logMessage(`Created socket at ${endpoint}`)
        } else {
            this.name = socketName;
            logMessage(`Created \`${this.name}\` socket at \`${endpoint}\``)
        }

        if (closeHandler === null) {
            this.socket.onclose = (event: CloseEvent) => {
                if (event.code === 1000) {
                    logMessage(`\`${this.name}\` socket closed in good health.`)
                    return;
                }
                logMessage(`\`${this.name}\` socket closed unexpectedly (\`${event.code}\`). Reason: \`${event.reason}\`.`)
            };
        } else {
            this.socket.onclose = closeHandler;
        }
    }

    bind(
        openHandler: (event: Event) => void,
        messageHandler: (event: MessageEvent) => void
    ) {
        this.socket.onopen = openHandler;
        this.socket.onmessage = messageHandler;
    }

    close() {
        this.socket.close();
        logMessage(`\`${this.name}\` socket closed by client.`)
    }

    sendString(data: string) {
        this.socket.send(data);
    }
}
