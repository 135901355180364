import { APISocket, logMessage } from "./sockets";
import { decodeJson, encodeJson } from "./serialization";

export interface User {
    readonly id: number,
    readonly username: string,
    readonly token: string,
    readonly salt: string,
    readonly email: string,
    readonly currentGame: String | null,
}

export interface UserResponse {
    readonly ok: boolean,
    readonly error: string,
    readonly user: User | null
}

export interface UserCreationRequest {
    readonly username: String,
    readonly password: String,
    readonly email: String,
}

export interface UserLoginRequest {
    readonly username: string,
    readonly password: string,
}

/**
 * Authenticate with token.
 */
export function tokenAuth(
    token: string,
    happyCallback: (user: User) => void,
    errorCallback: (error: string) => void
) {
    const socket = new APISocket("/account/tokenAuth", "Token Auth");
    socket.bind(
        (_) => {
            socket.sendString(token);
        },
        (event) => {
            processUserResponse(event.data, happyCallback, errorCallback);
            socket.close();
        }
    )
}

/**
 * Authenticate with username/password.
 */
export function usernamePasswordAuth(
    username: string,
    password: string,
    happyCallback: (user: User) => void,
    errorCallback: (error: string) => void
): void {
    const socket = new APISocket("/account/login", "UserPass Auth");
    socket.bind(
        (_) => {
            const loginRequestData: UserLoginRequest = {
                username: username,
                password: password
            }
            socket.sendString(encodeJson(loginRequestData));
        },
        (event) => {
            processUserResponse(event.data, happyCallback, errorCallback);
            socket.close();
        }
    )
}

function processUserResponse(
    data: string,
    happyCallback: (user: User) => void,
    errorCallback: (error: string) => void
) {
    const userResponse: UserResponse | null = decodeJson<UserResponse>(data);
    if (userResponse == null) {
        errorCallback("Error decoding JSON");
        return;
    }
    if (!userResponse.ok) {
        errorCallback(userResponse.error);
        return;
    }
    if (userResponse.user == null) {
        errorCallback("No user data returned from server");
        return;
    }
    happyCallback(userResponse.user);
}
