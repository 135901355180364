import React, { createRef, memo, useRef, useState } from "react";
import { Button, Card } from 'react-bootstrap';
import { MultiplayerChatMessage } from "../../types";
import { CSSTransition, TransitionGroup } from "react-transition-group";

interface ChatProps {
    chat: Array<MultiplayerChatMessage>,
    handler: (message: string) => void,
    chatScrollRef: React.MutableRefObject<HTMLDivElement | null>
}

function UnmemoizedChatComponent(props: ChatProps): React.JSX.Element {
    const [inputValue, setInputValue] = useState<string>("");
    const chatInputRef = useRef<HTMLInputElement | null>(null);

    function ChatMessageComponent(props: { data: MultiplayerChatMessage }) {
        const { data } = props;
        const bgColor = props.data.sender ? "#f8f9fa" : "#cfe2ff";
        return <div style={{
            backgroundColor: bgColor,
            padding: ".414rem",
            borderRadius: ".414rem",
            marginTop: ".414rem",
        }}>
            <div style={{ display: "flex" }}>
                <div style={{ fontWeight: "600" }}>
                    {props.data.sender ? <>{data.sender}< br /></> : <></>}
                </div>
                <div style={{
                    userSelect: "none",
                    fontSize: "66%",
                    marginLeft: "auto"
                }}>{data.timestamp}</div>
            </div>
            {props.data.message}
        </div>
    }

    return <Card>
        <Card.Header
            onClick={(_) => { chatInputRef.current?.focus(); }}
        >Chat</Card.Header>
        <Card.Body style={{ padding: "0" }}>
            <Card.Text>
                <div
                    ref={props.chatScrollRef}
                    style={{
                        height: "10em",
                        overflowY: "scroll",
                        padding: "0.414rem"
                    }}
                    onDoubleClick={(_) => { chatInputRef.current?.focus(); }}
                >
                    <TransitionGroup>
                        {props.chat.map((data: MultiplayerChatMessage, index: number) => {
                            const nodeRef = createRef<HTMLDivElement>();
                            return <CSSTransition
                                key={index}
                                nodeRef={nodeRef}
                                timeout={400}
                                classNames="chat-node"
                            >
                                <div ref={nodeRef}>
                                    <ChatMessageComponent data={data} />
                                </div>
                            </CSSTransition>
                        })}
                    </TransitionGroup>
                </div>
                <form onSubmit={(event) => {
                    event.preventDefault();
                    if (inputValue.length > 0) props.handler(inputValue.trim());
                    setInputValue("");
                }}>
                    <div style={{ display: "flex", padding: ".414rem" }}>
                        <input
                            ref={chatInputRef}
                            type="text"
                            className="form-control"
                            value={inputValue}
                            onChange={(event) => {
                                setInputValue(event.target.value);
                            }}
                        />
                        <Button type="submit">Send</Button>
                    </div>
                </form>
            </Card.Text>
        </Card.Body>
    </Card>
}

const chatEqual = (prevProps: ChatProps, nextProps: ChatProps) => {
    return prevProps.chat.length === nextProps.chat.length;
}

export const ChatComponent = memo(UnmemoizedChatComponent, chatEqual);

