import React, { PropsWithChildren, useState } from 'react';
import {
    Button,
    Col,
    Card,
    Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Tile, TileProps } from '../components/game/tile';
import { indexFromIdFieldArray } from '../util';
import { HorizontalSortableTiles } from '../components/game/word';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    closestCorners,
    DragOverlay,
    DragStartEvent,
    DragOverEvent,
    DragEndEvent,
    DragCancelEvent,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    rectSortingStrategy,
} from '@dnd-kit/sortable';

export function LinkContainer(props: PropsWithChildren<{ to: string }>): React.JSX.Element {
    return <div
        onClick={() => {
            window.location.assign(props.to);
        }}
    >
        {props.children}
    </div>
}

export function HomeComponent(): React.JSX.Element {
    const [banner, setBanner] = useState<Array<TileProps>>(
        Array.from("PIRATE SCRABBLE!").map((value, index) => {
            return {
                letter: value,
                id: `tile${index}`,
                faceUp: true,
            }
        })
    );

    const [currentDragLetter, setCurrentDragLetter] = useState<string>("");

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragStart(event: DragStartEvent) {
        const index: number = indexFromIdFieldArray(banner, event.active.id.toString());
        setCurrentDragLetter(banner[index].letter);
    }

    function handleDragOver(event: DragOverEvent) {

    }

    function handleDragEnd(event: DragEndEvent) {
        setCurrentDragLetter("");
    }

    return <div>
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
        >
            <div style={{ display: "flex", paddingBottom: "1rem" }}>
                <HorizontalSortableTiles
                    id="banner"
                    items={banner}
                />
            </div>
            <DragOverlay>
                <Tile id={"tile-overlay"} letter={currentDragLetter} faceUp={true} />
            </DragOverlay>
        </DndContext>
        <Row>
            <Col>
                <Card>
                    <Card.Header>Puzzles</Card.Header>
                    <Card.Body>
                        <Card.Title>Work on your skills with puzzles</Card.Title>
                        <Card.Text>
                            Practice makes perfect
                        </Card.Text>
                        <LinkContainer to="/puzzles">
                            <Button variant="primary">Go to puzzles</Button>
                        </LinkContainer>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card>
                    <Card.Header>Multiplayer</Card.Header>
                    <Card.Body>
                        <Card.Title>Play a live game</Card.Title>
                        <Card.Text>
                            Entertain your competitive nature
                        </Card.Text>
                        <LinkContainer to="/multiplayer">
                            <Button variant="primary">Go to multiplayer</Button>
                        </LinkContainer>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row className='mt-3'>
            <Col>
                <Card>
                    <Card.Header>Rules</Card.Header>
                    <Card.Body>
                        <Card.Title>What is it?</Card.Title>
                        <Card.Text>
                            Pirate Scrabble is a game played with letter tiles.
                        </Card.Text>
                        <LinkContainer to="/rules">
                            <Button variant="primary">Go to full ruleset</Button>
                        </LinkContainer>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </div>;
}
