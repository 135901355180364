import React, { useRef } from "react";
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CSSTransition } from 'react-transition-group';

export interface TileProps {
    letter: string, /* The tile's letter */
    id: string, /* Unique among siblings, i.e. original index of the tile in it's parent list */
    faceUp: boolean
}

export function Tile(props: TileProps): React.JSX.Element {
    const nodeRef = useRef(null);
    return <CSSTransition
        nodeRef={nodeRef}
        in={props.faceUp}
        timeout={400}
        classNames="tile-transition"
    >
        <div ref={nodeRef}>
            <div className={props.faceUp ? "tile" : "tile tile-back"}>
                {props.letter}
            </div>
        </div>
    </CSSTransition>
}

export function SortableTile(props: TileProps): React.JSX.Element {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        display: "block",
        touchAction: "none"
    };

    return <div
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={style}
    >
        <Tile {...props} />
    </div>
}
