import { useContext } from 'react';
import {
    Container,
    Nav,
    Navbar,
    Offcanvas,
} from 'react-bootstrap';
import { UserContext } from '../app';

export function NavbarComponent(props: { logoutHandler: () => void }) {
    const { user, setUser, reauthenticate } = useContext(UserContext);
    const expand = false;
    return <Navbar bg="light" expand={expand} className="mb-3">
        <Container fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Brand href="/" className="prettyTitle">Pirate Scrabble</Navbar.Brand>
            <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="start"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className="prettyTitle">
                        Pirate Scrabble
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="justify-content-end pe-3">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/puzzles">Puzzles</Nav.Link>
                        <Nav.Link href="/multiplayer">Multiplayer</Nav.Link>
                        <Nav.Link href="/rules">Rules</Nav.Link>
                        <Nav.Link href="/about">About</Nav.Link>
                    </Nav>
                    <Nav className='ms-auto w-100 justify-content-end pe-3'>
                        {!user ? <>
                            <Nav.Link href="/login">Log in</Nav.Link>
                        </> : <>
                            <Nav.Link disabled={true}>Logged in as {user.username}</Nav.Link>
                            <Nav.Link onClick={props.logoutHandler}>Log out</Nav.Link>
                        </>
                        }
                    </Nav>
                </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Container>
    </Navbar>
}
