import React, { createRef, memo } from "react";
import { useDroppable } from '@dnd-kit/core';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    closestCorners,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    rectSortingStrategy,
    horizontalListSortingStrategy,
    useSortable,
} from '@dnd-kit/sortable';
import { SortableTile, Tile, TileProps } from './tile';
import { CSS } from "@dnd-kit/utilities";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Flipper, Flipped } from 'react-flip-toolkit';
import { Word } from "../../types";

/**
 * A user's word list.
 */
export function SortablePlayerWords(props: {
    id: string,
    words: Array<Word> | undefined,
}): React.JSX.Element {
    const { setNodeRef } = useDroppable({
        id: props.id
    });

    if (props.words === undefined)
        return <></>;

    return <SortableContext
        id={props.id}
        items={props.words}
        strategy={rectSortingStrategy}
    >
        <div ref={setNodeRef}>
            <TransitionGroup>
                {props.words.map((word: Word, index: number) => {
                    const nodeRef = createRef<HTMLDivElement>();
                    return <CSSTransition
                        key={`${word.id}-${word.history[0]}`}
                        nodeRef={nodeRef}
                        timeout={400}
                        classNames="word-node"
                    >
                        <div ref={nodeRef}>
                            <SortableWord id={word.id} word={word} />
                        </div>
                    </CSSTransition>
                })}
            </TransitionGroup>
        </div>
    </SortableContext>
}

/**
 * Just a list of tiles. No frills.
 * By default, renders vertically but can be wrapped in flex container.
 */
export function PlainWord(props: {
    word: Word
}): React.JSX.Element {
    return <>
        {Array.from(props.word.history[0]).map(
            (letter: string, index: number) =>
                <Tile
                    key={index}
                    id={index.toString()}
                    letter={letter}
                    faceUp={true}
                />
        )}
    </>
}

/**
 * Render a list of tiles horizontally. Cannot individually sort.
 * Can be an item of a vertically sortable list.
 */
export function SortableWord(props: {
    id: string,
    word: Word
}): React.JSX.Element {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id: props.id
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        display: "flex",
        touchAction: "none"
    };

    return <div
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={style}
    >
        <PlainWord word={props.word} />
    </div>
}

interface SortableTileProps {
    id: string,
    tiles: Array<TileProps> | undefined
}

/**
 * Public tile area.
 */
export function SortablePublicTiles(props: SortableTileProps): React.JSX.Element {
    const { setNodeRef } = useDroppable({
        id: props.id
    });

    if (props.tiles === undefined) return <div></div>

    const flipKey = props.tiles.map((tileProps: TileProps, _: number) => {
        return `${tileProps.letter}${tileProps.faceUp}`
    }).join('');

    return <SortableContext
        id={props.id}
        items={props.tiles}
        strategy={rectSortingStrategy}
    >
        <Flipper flipKey={flipKey}>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {props.tiles.map((tileProps: TileProps, _: number) => {
                    return <div key={tileProps.id}>
                        <Flipped flipId={tileProps.id}>
                            <div> {/*For some unknown reason this parent has to exist*/}
                                <SortableTile {...tileProps} />
                            </div>
                        </Flipped>
                    </div>
                })}
            </div>
        </Flipper>
    </SortableContext>
}

export const MemoSortablePublicTiles = memo(SortablePublicTiles,
    (prevProps: SortableTileProps, nextProps: SortableTileProps) => { return JSON.stringify(prevProps) === JSON.stringify(nextProps); }
);

/**
 * Render a horizontal word with sortable tiles.
 */
export function HorizontalSortableTiles(props: {
    id: string,
    items: Array<TileProps>
}): React.JSX.Element {
    const { setNodeRef } = useDroppable({
        id: props.id
    });

    return <SortableContext
        id={props.id}
        items={props.items}
        // strategy={horizontalListSortingStrategy}
        strategy={rectSortingStrategy}
    >
        <div ref={setNodeRef} style={{ display: "flex" }}>
            {props.items.map((tileProps, _) =>
                <SortableTile
                    key={tileProps.id}
                    {...tileProps}
                />)}
            <div style={{ visibility: "hidden" }}>
                <Tile id={"hidden-tile-do-not-reference"} letter=" " faceUp={false} />
            </div>
        </div>
    </SortableContext>
}
