import { APISocket } from "./sockets";
import { User } from "./user";
import { GameStateUpdate, MultiplayerAction, MultiplayerActionResponse, MultiplayerGame } from "../types";
import { decodeJson } from "./serialization";

export function createNewGame(
    currentUser: User,
    happyCallback: (gameData: MultiplayerGame) => void,
    errorCallback: (message: string) => void
) {
    const socket = new APISocket("/multiplayer/create", "Create Game");
    socket.bind(
        (event) => {
            socket.sendString(currentUser.token);
        },
        (event) => {
            let data: MultiplayerActionResponse | null = decodeJson<MultiplayerActionResponse>(event.data);
            if (data === null) {
                errorCallback("Error decoding create game JSON response");
                return;
            }
            if (!data.ok) {
                errorCallback(data.errorMessage);
                return;
            }
            if (data.game === null) {
                errorCallback("Received game state is null")
                return;
            }
            happyCallback(data.game);
        }
    )
}

export function listGames(
    currentUser: User,
    happyCallback: (gameData: Array<string>) => void,
    errorCallback: (message: string) => void
) {
    const socket = new APISocket("/multiplayer/list", "List Games");
    socket.bind(
        (_) => {
            socket.sendString(currentUser.token);
        },
        (event) => {
            const data: Array<string> | null = decodeJson<Array<string>>(event.data);
            if (data === null) {
                errorCallback("Error decoding list games JSON response");
                return;
            }
            happyCallback(data);
        }
    )
}

export function chatActionBuilder(userId: number, message: string): MultiplayerAction {
    return {
        playerId: userId,
        actionType: "CHAT",
        action: null,
        data: message,
    }
}

export function startActionBuilder(userId: number): MultiplayerAction {
    return {
        playerId: userId,
        actionType: "START",
        action: null,
        data: "",
    }
}

export function endActionBuilder(userId: number): MultiplayerAction {
    return {
        playerId: userId,
        actionType: "END",
        action: null,
        data: "",
    }
}

export function flipActionBuilder(userId: number, userIndex: number, tileId: string): MultiplayerAction {
    return {
        playerId: userId,
        actionType: "ACTION",
        action: {
            actionType: "FLIP",
            flippedTileId: tileId,
            claimWord: "",
            stolenWordId: "",
            actingPlayer: userIndex,
            stolenPlayer: null,
        },
        data: "",
    }
}

export function claimFromPublicActionBuilder(userId: number, userIndex: number, word: string): MultiplayerAction {
    return {
        playerId: userId,
        actionType: "ACTION",
        action: {
            actionType: "CLAIM",
            flippedTileId: "",
            claimWord: word,
            stolenWordId: "",
            actingPlayer: userIndex,
            stolenPlayer: null,
        },
        data: "",
    }
}

/**
 * Create a steal action.
 */
export function stealActionBuilder(
    userId: number,
    userIndex: number,
    stolenPlayer: number,
    stolenWordId: string,
    claimWord: string
): MultiplayerAction {
    return {
        playerId: userId,
        actionType: "ACTION",
        action: {
            actionType: "CLAIM",
            flippedTileId: "",
            claimWord: claimWord,
            stolenWordId: stolenWordId,
            actingPlayer: userIndex,
            stolenPlayer: stolenPlayer,
        },
        data: "",
    }
}

export function buzzActionBuilder(
    userId: number
): MultiplayerAction {
    return {
        playerId: userId,
        actionType: "BUZZ",
        action: null,
        data: "",
    }
}
