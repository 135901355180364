import React, { ChangeEvent, useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../app"
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row, ButtonGroup } from "react-bootstrap";
import { MultiplayerAction, MultiplayerActionResponse, MultiplayerGame, GameStateUpdate, Word } from "../types";
import { APISocket, logMessage } from "../api/sockets";
import {
  buzzActionBuilder,
  chatActionBuilder,
  claimFromPublicActionBuilder,
  createNewGame, endActionBuilder,
  flipActionBuilder,
  listGames,
  startActionBuilder,
  stealActionBuilder
} from "../api/multiplayerUtil";
import { User, tokenAuth } from "../api/user";
import { decodeJson, encodeJson } from "../api/serialization";
import { ChatComponent } from "./game/chat";
import { HorizontalSortableTiles, PlainWord, MemoSortablePublicTiles } from "./game/word";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { PlayerWords, MemoPlayerWords } from "./game/playerWords";
import { Tile, TileProps } from "./game/tile";
import { indexFromIdFieldArray, valueFromIdFieldArray } from "../util";

interface StagingProps {
  word: Word,
  owner: number,
  tiles: Array<TileProps>,
}

/**
 * Render a multiplayer game.
 */
export function RenderGameComponent(props: {
  game: MultiplayerGame,
  gameHash: number,
  user: User,
  error: string,
  setError: React.Dispatch<React.SetStateAction<string>>,
  handleAction: (action: MultiplayerAction) => void,
  chatScrollRef: React.MutableRefObject<HTMLDivElement | null>,
  chatScrollToRecent: () => void,
}): React.JSX.Element {
  const { game, gameHash, user, error, setError, handleAction, chatScrollRef, chatScrollToRecent } = props;
  const [drag, setDrag] = useState<{ letters: string | null, faceUp: boolean, areaId: string, areaIndex: number }>({
    letters: null,
    faceUp: false,
    areaId: "",
    areaIndex: -1
  });
  const [staged, setStaged] = useState<StagingProps | null>(null);
  const [chatLength, setChatLength] = useState<number>(game.chat.length);
  const [previousAction, setPreviousAction] = useState<GameStateUpdate | null>(game.lastAction);
  const [shownCard, setShownCard] = useState<string>(user.username);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const PUBLIC_TILES_ID: string = "multiplayer-public-tiles";
  const STAGING_TILES_ID: string = "multiplayer-staging-tiles";
  const ALL_WORDS_ZONE_ID: string = "multiplayer-all-words";
  const PLAYER_WORDS_IDS = [
    "multiplayer-p0-words",
    "multiplayer-p1-words",
    "multiplayer-p2-words",
    "multiplayer-p3-words",
  ];
  const userIndex = game.playerIds.indexOf(user.id);
  const ALL_WORDS_DUMMY_USERNAME: string = "__all__";
  const allWords: Array<Word> = [];
  for (let i = 0; i < game.state.playerWords.length; i++) {
    allWords.push(...game.state.playerWords[i]);
  }
  const buzz = () => {
    handleAction(buzzActionBuilder(user.id));
    setError("");
  };
  const flipFirst = (): void => {
    let firstTileId: string = "";
    for (let i = 0; i < game.state.tiles.length; i++) {
      if (!game.state.tiles[i].faceUp) {
        firstTileId = game.state.tiles[i].id;
        break;
      }
    }
    handleAction(flipActionBuilder(user.id, userIndex, firstTileId));
  };
  useEffect(() => {
    chatScrollToRecent();
  }, []);
  useEffect(() => {
    document.onkeydown = (event: KeyboardEvent) => {
      if (event.key === " ") {
        if (document.activeElement?.nodeName.toLowerCase() !== "input") {
          event.preventDefault();
          buzz();
        }
      } else if (event.key === "f") {
        if (document.activeElement?.nodeName.toLowerCase() !== "input") {
          flipFirst();
        }
      }
    }
  }, [buzz, flipFirst]);
  useEffect(() => {
    setChatLength((oldLength) => {
      if (oldLength < game.chat.length) chatScrollToRecent();
      return game.chat.length;
    });
  }, [game.chat]);
  useEffect(() => {
    setPreviousAction((prevState: GameStateUpdate | null) => {
      if (JSON.stringify(prevState) === JSON.stringify(game.lastAction)) return game.lastAction;

      if (game.lastAction) {
        if (game.lastAction.actionType === "CLAIM") {
          if (game.lastAction.stolenWordId === staged?.word.id) {
            setStaged((_: StagingProps | null) => null);
          }
        }
      }
      setError("");
      //TODO animate or something idk
      return game.lastAction;
    });
  }, [game.lastAction]);
  const sendMessage = (message: string) => {
    handleAction(chatActionBuilder(user.id, message));
  };
  const tabClick = (username: string) => {
    setShownCard(username);
  };
  const renderPoints = (index: number, show: boolean): string => {
    if (!show) return "";
    if (game.phase !== "FINISHED") return "";
    let score = 0;
    const playerWords = game.state.playerWords[index];
    for (let i = 0; i < playerWords.length; i++) {
      score += playerWords[i].history[0].length - game.state.wordMinimumSize + 1;
    }
    let result = ` ${score} points`;
    if (score == 1)
      result = ` ${score} point`;
    return " - " + result;
  };
  const getWordOwner = (word: Word): number => {
    for (let i = 0; i < game.state.playerWords.length; i++) {
      for (let k = 0; k < game.state.playerWords[i].length; k++) {
        if (game.state.playerWords[i][k].id === word.id) return i;
      }
    }
    return -1;
  };
  const handleDragStart = (event: DragStartEvent) => {
    const sourceZoneId: string | undefined = event.active.data.current?.sortable.containerId.toString();
    const sourceItemId: string | undefined = event.active.id.toString();

    if (sourceZoneId === undefined) return;
    if (sourceItemId === undefined) return;

    const playerDrag = PLAYER_WORDS_IDS.indexOf(sourceZoneId)
    if (sourceZoneId === ALL_WORDS_ZONE_ID) {
      const words = allWords;
      setDrag({
        letters: valueFromIdFieldArray(words, sourceItemId.toString()).history[0],
        faceUp: true,
        areaId: ALL_WORDS_ZONE_ID,
        areaIndex: indexFromIdFieldArray(words, sourceItemId.toString())
      });
    } else if (playerDrag !== -1) {
      const words = game.state.playerWords[playerDrag];
      setDrag({
        letters: valueFromIdFieldArray(words, sourceItemId.toString()).history[0],
        faceUp: true,
        areaId: sourceZoneId,
        areaIndex: indexFromIdFieldArray(words, sourceItemId.toString())
      });
    } else if (sourceZoneId === PUBLIC_TILES_ID) {
      const tile = valueFromIdFieldArray(game.state.tiles, sourceItemId.toString());
      setDrag({
        letters: tile.letter,
        faceUp: tile.faceUp,
        areaId: sourceZoneId,
        areaIndex: indexFromIdFieldArray(game.state.tiles, sourceItemId.toString())
      });
    }
  }
  const handleDragOver = (event: DragOverEvent) => { }
  const handleDragEnd = (event: DragEndEvent) => {
    const sourceZoneId: string | undefined = drag.areaId;
    const sourceItemId: string | undefined = event.active.id.toString();
    let targetZoneId: string | undefined = event.over?.data.current?.sortable?.containerId.toString();
    const targetItemId: string | undefined = event.over?.id.toString();
    setDrag({ letters: null, faceUp: false, areaId: "", areaIndex: -1 });
    if (targetZoneId === undefined) {
      if (targetItemId === undefined) return;
      if (targetItemId === STAGING_TILES_ID) {
        targetZoneId = STAGING_TILES_ID;
      }
    }
    if (sourceZoneId === ALL_WORDS_ZONE_ID && targetZoneId === STAGING_TILES_ID) {
      let word: Word = valueFromIdFieldArray(allWords, sourceItemId.toString());
      setStaged((_) => {
        return {
          word: word,
          owner: getWordOwner(word),
          tiles: Array.from(word.history[0]).map((letter, index): TileProps => {
            return {
              id: `multiplayer-staging-tile-${index}`,
              letter: letter,
              faceUp: true
            }
          })
        }
      });
    } else {
      for (let i = 0; i < 4; i++) {
        if (sourceZoneId === PLAYER_WORDS_IDS[i] && targetZoneId === STAGING_TILES_ID) {
          let word: Word = valueFromIdFieldArray(game.state.playerWords[i], sourceItemId.toString());
          setStaged((_) => {
            return {
              word: word,
              owner: i,
              tiles: Array.from(word.history[0]).map((letter, index): TileProps => {
                return {
                  id: `multiplayer-staging-tile-${index}`,
                  letter: letter,
                  faceUp: true
                }
              })
            }
          });
          break;
        }
      }
    }
  }

  return <DndContext
    sensors={sensors}
    collisionDetection={closestCenter}
    onDragStart={handleDragStart}
    onDragOver={handleDragOver}
    onDragEnd={handleDragEnd}
  >
    <Row>
      <Col>
        <GameInputArea
          game={game}
          user={user}
          userIndex={userIndex}
          error={error}
          staged={staged}
          setStaged={setStaged}
          stagingId={STAGING_TILES_ID}
          handleAction={handleAction}
          buzz={buzz}
        />
        <div style={{ height: "3rem" }}></div>
        <div style={{ display: "flex" }}>
          <PlayerTabButton
            handler={tabClick}
            username={user.username}
            title={`You (${game.state.playerWords[userIndex].length})`}
            active={shownCard === user.username}
          />
          {
            game.playerNames.map((username: string, index: number) => {
              const key = `${username}-tab-key`;
              if (index === userIndex) {
                return <div key={key}></div>;
              }
              return <PlayerTabButton
                key={key}
                handler={tabClick}
                username={username}
                title={`${username} (${game.state.playerWords[index].length})`}
                active={shownCard === username
                }
              />
            })
          }
          <PlayerTabButton
            handler={tabClick}
            username={ALL_WORDS_DUMMY_USERNAME}
            title={`All words (${allWords.length})`}
            active={shownCard === ALL_WORDS_DUMMY_USERNAME}
          />
        </div>
        <div style={{ display: shownCard === user.username ? "block" : "none" }}>
          {shownCard === user.username ?
            <MemoPlayerWords
              title={`Your words` + renderPoints(userIndex, true)} //TODO: show points when game over
              words={game.state.playerWords[userIndex]}
              id={PLAYER_WORDS_IDS[userIndex]}
            /> : <></>}
        </div>
        {
          game.playerNames.map((username: string, index: number) => {
            const key = `multiplayer-word-card-${username}`;
            if (index === userIndex) {
              return <div key={key}></div>;
            }
            const words = game.state.playerWords[index];
            return <div key={key}>
              <div style={{ display: shownCard === username ? "block" : "none" }}>
                {shownCard === username ?
                  <MemoPlayerWords
                    title={`${username}'s words` + renderPoints(index, true)}
                    words={words}
                    id={PLAYER_WORDS_IDS[index]}
                  /> : <></>}
              </div>
            </div>
          })
        }
        <div style={{ display: shownCard === ALL_WORDS_DUMMY_USERNAME ? "block" : "none" }}>
          {shownCard === ALL_WORDS_DUMMY_USERNAME ?
            <MemoPlayerWords
              title={"All player words"}
              words={allWords}
              id={ALL_WORDS_ZONE_ID}
            /> : <></>}
        </div>
      </Col>
      <Col>
        <GameShareLink
          game={game}
        />
        <div style={{ height: "2rem" }}></div>
        <GameControlArea
          game={game}
          user={user}
          userIndex={userIndex}
          handleAction={handleAction}
          flip={flipFirst}
        />
        <div style={{ height: "2rem" }}></div>
        <MemoSortablePublicTiles
          id={PUBLIC_TILES_ID}
          tiles={game.state.tiles}
        />
        <div style={{ height: "2rem" }}></div>
        <ChatComponent
          chat={game.chat}
          handler={sendMessage}
          chatScrollRef={chatScrollRef}
        />
      </Col>
    </Row>
    <div style={{ height: "2rem" }}></div>
    <GameDragOverlay drag={drag} />
  </DndContext>
}

function GameDragOverlay(props: {
  drag: {
    letters: string | null,
    faceUp: boolean
  }
}): React.JSX.Element {
  const { drag } = props;
  if (drag.letters === null) return <></>

  if (drag.letters.length === 1) {
    return <DragOverlay>
      <Tile id={"overlayTile"} letter={drag.letters} faceUp={drag.faceUp} />
    </DragOverlay>
  }

  return <DragOverlay>
    <div style={{ display: "flex" }}>
      <PlainWord word={{ id: "overlayWord", history: [drag.letters] }} />
    </div>
  </DragOverlay>
}

function GameInputArea(props: {
  game: MultiplayerGame,
  user: User,
  userIndex: number,
  error: string,
  staged: StagingProps | null,
  setStaged: any,
  stagingId: string,
  handleAction: (action: MultiplayerAction) => void,
  buzz: () => void,
}): React.JSX.Element {
  const { game, user, userIndex, error, staged, setStaged, stagingId, handleAction, buzz } = props;
  const [inputValue, setInputValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const buzzerRef = useRef<HTMLButtonElement | null>(null);
  const submitRef = useRef<HTMLButtonElement | null>(null);
  const BUZZ_TIME = 5;



  useEffect(() => {
    const inputNode = inputRef.current;
    const buzzerNode = buzzerRef.current;
    const submitNode = submitRef.current;
    if (submitNode === null) return;
    if (buzzerNode === null) return;
    if (inputNode === null) return;
    if (game.buzzHolder === user.id) {
      submitNode.style.display = "inline-block";
      buzzerNode.style.display = "none";
      setInputValue((_) => "");
      inputNode.disabled = false;
      inputNode.focus();
    } else {
      submitNode.style.display = "none";
      buzzerNode.style.display = "inline-block";
      inputNode.blur();
      inputNode.disabled = true;
    }
  }, [game.buzzHolder]);

  const renderWordHistory = (word: Word): string => word.history.slice().reverse().join("->");
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => { setInputValue(event.target.value.toUpperCase()); };
  const clearStaging = () => { setStaged((_: StagingProps | null) => null); };
  const submit = () => {
    if (inputValue.length === 0) {
      return;
    }
    const sanitized = inputValue.trim();
    if (staged) {
      handleAction(stealActionBuilder(
        user.id,
        userIndex,
        staged.owner,
        staged.word.id,
        sanitized
      ));
      setStaged((_: StagingProps | null) => null);
    } else {
      handleAction(claimFromPublicActionBuilder(
        user.id, userIndex, sanitized
      ));
    }
    const inputNode = inputRef.current;
    if (inputNode) {
      inputNode.blur();
      inputNode.disabled = true;
    }
    setInputValue((_) => "");
  };

  return <div>
    <div style={{ display: "flex" }} >
      {game.buzzHolder ? <div>&nbsp;
        <i>{game.playerNames[game.playerIds.indexOf(game.buzzHolder)]}</i>
        &nbsp;holds the buzzer. &nbsp;
        {BUZZ_TIME - (game.buzzElapsed === null ? 0 : game.buzzElapsed)}...
      </div> :
        <></>}
      <div style={{ visibility: "hidden" }}>HIDDEN</div>
    </div>
    <div>
      <div style={{ display: "flex" }}>
        <input
          ref={inputRef}
          className={"form-control"}
          value={inputValue}
          onChange={handleChange}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") { submit() }
          }}
        />
        <Button
          ref={buzzerRef}
          onClick={buzz}
        >Buzz</Button>
        <Button
          ref={submitRef}
          onClick={(_) => { submit() }}
          type={"submit"}
        >Claim</Button>
      </div>
    </div>
    <GameErrorArea errorMessage={error} />
    <div style={{ height: "2rem" }}></div>
    <div>
      <div style={{
        width: "100%",
        minHeight: "2rem",
        border: "1px grey solid",
        borderRadius: ".22rem",
        padding: ".0875rem"
      }}>
        <HorizontalSortableTiles id={stagingId} items={staged ? staged.tiles : []} />
      </div>
      {
        staged ? <div style={{ display: "flex" }}>
          <div><span style={{ fontWeight: "600" }}>{renderWordHistory(staged.word)}</span> <i>from {game.playerNames[staged.owner]} </i></div>
          <div
            onClick={clearStaging}
            style={{ cursor: "pointer", marginLeft: "auto", textDecoration: "underline" }}
          >Remove</div>
        </div> : <div>
          Drag a word to the box above to steal it.
        </div>
      }
    </div>
  </div>
}

//TODO: show winner
function GameControlArea(props: {
  game: MultiplayerGame,
  user: User,
  userIndex: number,
  handleAction: (action: MultiplayerAction) => void,
  flip: () => void,
}): React.JSX.Element {
  const { game, user, userIndex, handleAction, flip } = props;

  const startGame = (): void => { handleAction(startActionBuilder(user.id)); };
  const endGame = (): void => {
    if (prompt("Type END to confirm.") !== "END") return;
    handleAction(endActionBuilder(user.id));
  };

  return <div>
    <div>{game.playerNames[0]} is in control of the game.</div>
    {
      game.phase === "CREATED" ? <div>Waiting for players...</div> : <></>
    }
    {
      game.phase === "FINISHED" ? <div>Game ended.</div> : <></>
    }
    <ButtonGroup>
      {
        game.phase === "ONGOING" ? <Button onClick={flip}>Flip</Button> : <></>
      }
      {
        userIndex === 0 ? <>
          {
            game.phase === "CREATED" ? <Button onClick={startGame}>Start Game</Button> : <></>
          }
          {
            game.phase !== "FINISHED" ? <Button onClick={endGame}>End Game</Button> : <></>
          }
        </> : <></>
      }

    </ButtonGroup>
  </div>
}

function PlayerTabButton(props: {
  username: string,
  title: string,
  handler: (username: string) => void,
  active: boolean,
}): React.JSX.Element {
  const { username, title, handler, active } = props;
  const border = active ? "2px #cccccc solid" : "1px #cccccc solid";
  return <div
    onClick={() => { handler(username); }}
    style={{
      userSelect: "none",
      borderLeft: border,
      borderRight: border,
      borderTop: border,
      borderRadius: ".25rem .25rem 0 0",
      cursor: "pointer",
      padding: ".25rem",
    }}
  >
    {title}
  </div>
}

function GameErrorArea(props: {
  errorMessage: string,
}): React.JSX.Element {
  const { errorMessage } = props;
  return <div style={{ display: "flex", color: "red" }}>
    {errorMessage}
    <div style={{ visibility: "hidden" }}>HIDEME</div>
  </div>
}

function GameShareLink(props: {
  game: MultiplayerGame,
}): React.JSX.Element {
  const { game } = props;

  const windowLocation = window.location.toString();

  if (game.phase === "CREATED") {
    return <div>
      Share link: <a href={windowLocation}>{windowLocation}</a>
    </div>
  }
  return <></>
}

