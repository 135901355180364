import React, { ChangeEvent, useEffect, useState } from "react"
import { APISocket, logMessage } from "../api/sockets";
import { GameState, GameStateUpdate, Word } from "../types";
import { decodeJson, encodeJson } from "../api/serialization";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    closestCorners,
    DragOverlay, DragEndEvent, DragOverEvent, DragStartEvent, UniqueIdentifier,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Col, Row } from "react-bootstrap";
import { HorizontalSortableTiles, PlainWord, SortablePlayerWords, SortablePublicTiles, SortableWord } from "./game/word";
import { Tile, TileProps } from "./game/tile";
import { AutoFocusInput } from "./input";
import { valueFromIdFieldArray } from "../util";

interface Puzzle {
    readonly id: number,
    readonly gameState: GameState,
    readonly solution: string
}

interface PuzzleSolutionAttempt {
    readonly id: number,
    readonly gameStateUpdate: GameStateUpdate
}

interface PuzzleSolutionResponse {
    ok: boolean,
    points: number,
    error: string
}

export function PuzzleComponent(props: any): React.JSX.Element {
    const PUZZLE_RESPONSE_LOADING = "loading";
    const PLAYER_WORDS_ID: string = "p1words";
    const PUBLIC_TILES_ID: string = "publicTiles";
    const STAGING_TILES_ID: string = "staging";

    const [currentPuzzle, setCurrentPuzzle] = useState<Puzzle | null>(null);
    const [currentInputValue, setCurrentInputValue] = useState<string>("");
    const [puzzleResponse, setPuzzleResponse] = useState<string | null>(null);
    const [stagedWord, setStagedWord] = useState<Word | null>(null);
    const [currentStaging, setCurrentStaging] = useState<Array<TileProps>>([]);
    const [currentDrag, setCurrentDrag] = useState<string | null>(null);


    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        setCurrentStaging(Array.from("DRAG HERE!").map((letter, index) => {
            return { letter: letter, id: index.toString(), faceUp: true }
        }));
        loadNewPuzzle();
    }, [])

    function loadNewPuzzle(): void {
        const socket = new APISocket("/puzzle/get", "Obtain puzzle");
        socket.bind(
            (event) => {
            },
            (event) => {
                const puzzle = decodeJson<Puzzle>(event.data);
                setCurrentPuzzle(puzzle);
            }
        )
    }

    function handleSubmit(guess: string): void {
        if (guess.length < 4 || guess.includes(" ")) {
            setPuzzleResponse("Error: Word must be 4 or more letters and not contain spaces.");
            return;
        }
        setPuzzleResponse(PUZZLE_RESPONSE_LOADING);
        const attemptPayload: PuzzleSolutionAttempt = {
            id: currentPuzzle ? currentPuzzle.id : -1,
            gameStateUpdate: {
                actionType: "CLAIM",
                flippedTileId: "",
                claimWord: guess,
                stolenWordId: stagedWord ? stagedWord.id : "",
                actingPlayer: 0,
                stolenPlayer: stagedWord ? 0 : null,
            }
        };
        logMessage(attemptPayload);
        const socket = new APISocket("/puzzle/attempt", "Solution");
        socket.bind(
            (event) => {
                socket.sendString("FakeToken"); // first message is token
                socket.sendString(encodeJson(attemptPayload));
            },
            (event) => {
                const response = decodeJson<PuzzleSolutionResponse>(event.data);
                if (response === null) return;
                let message = "";
                if (response.ok) {
                    message = `Correct. ${response.points} points.`
                } else {
                    message = `Error: ${response.error}`
                }
                setPuzzleResponse(message);
                socket.close();
            }
        );
    }

    function handleDragStart(event: DragStartEvent) {
        if (currentPuzzle === null) return;
        const sourceZoneId: UniqueIdentifier | undefined = event.active.data.current?.sortable.containerId;
        const sourceItemId: UniqueIdentifier | undefined = event.active.id;

        if (sourceZoneId === PLAYER_WORDS_ID) {
            setCurrentDrag(valueFromIdFieldArray(currentPuzzle.gameState.playerWords[0], sourceItemId.toString()).history[0]);
        } else if (sourceZoneId === PUBLIC_TILES_ID) {
            setCurrentDrag(valueFromIdFieldArray(currentPuzzle.gameState.tiles, sourceItemId.toString()).letter);
        } else if (sourceZoneId === STAGING_TILES_ID) {
            setCurrentDrag(valueFromIdFieldArray(currentStaging, sourceItemId.toString()).letter);
        }
    }

    function handleDragEnd(event: DragEndEvent) {
        if (currentPuzzle === null) return;
        setCurrentDrag(null);
        const sourceZoneId: UniqueIdentifier | undefined = event.active.data.current?.sortable.containerId;
        const sourceItemId: UniqueIdentifier | undefined = event.active.id;
        let targetZoneId: UniqueIdentifier | undefined = event.over?.data.current?.sortable.containerId;
        const targetItemId: UniqueIdentifier | undefined = event.over?.id;

        logMessage(`${sourceZoneId}.${sourceItemId}->${targetZoneId}.${targetItemId}`)

        if (targetZoneId === undefined) {
            if (targetItemId === undefined) return;
            if (targetItemId === STAGING_TILES_ID) {
                targetZoneId = STAGING_TILES_ID;
            }
        }

        if (sourceZoneId === PLAYER_WORDS_ID && targetZoneId === STAGING_TILES_ID) {
            // PLAYER WORD -> STAGING
            let word: Word = valueFromIdFieldArray(currentPuzzle.gameState.playerWords[0], sourceItemId.toString());
            setStagedWord(word);
            setCurrentStaging(Array.from(word.history[0]).map((letter, index): TileProps => {
                return {
                    id: `staging-tile-${index}`,
                    letter: letter,
                    faceUp: true
                }
            }));
            return;
        }
    }

    function CustomDragOverlay(props: { dragData: string | null }) {
        if (props.dragData === null) return <></>

        if (props.dragData.length === 1) {
            return <DragOverlay>
                <Tile id={"overlayTile"} letter={props.dragData} faceUp={true} />
            </DragOverlay>
        }

        return <DragOverlay>
            <div style={{ display: "flex" }}>
                <PlainWord word={{ id: "overlayWord", history: [props.dragData] }} />
            </div>
        </DragOverlay>
    }

    return <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragOver={(event) => {
        }}
        onDragEnd={handleDragEnd}
    >
        <Row className="mt-3">
            <Col>
                <div>
                    <form onSubmit={(event) => {
                        event.preventDefault();
                        handleSubmit(currentInputValue);
                    }}>
                        <div style={{ display: "flex" }}>
                            <AutoFocusInput
                                type="text"
                                className="form-control"
                                value={currentInputValue}
                                disabled={puzzleResponse === "loading"}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setCurrentInputValue(event.target.value.toUpperCase());
                                }}
                            />
                            <Button
                                type="submit"
                                disabled={puzzleResponse === PUZZLE_RESPONSE_LOADING}
                            >Submit</Button>
                        </div>
                        <div>
                            {puzzleResponse ?
                                (puzzleResponse === PUZZLE_RESPONSE_LOADING ? "Waiting for server..." : puzzleResponse)
                                : ""}
                        </div>
                    </form>
                </div>

            </Col>
            <Col>
                <h1>{currentPuzzle ? `Puzzle #${currentPuzzle.id}` : "Loading..."}</h1>
                <p>Try to solve the puzzle. You get more points for each correct guess.</p>
                <p>Base score is calculated on word size.</p>

                <p>Drag a word onto the staging area to steal from it. Otherwise, you are taking from the public tiles.</p>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col>
                <div>
                    <Button
                        disabled={stagedWord === null}
                        onClick={() => {
                            setCurrentStaging(Array.from("DRAG HERE!").map((letter, index) => {
                                return { letter: letter, id: index.toString(), faceUp: true };
                            }));
                            setStagedWord(null);
                        }}
                    >Remove</Button>
                    <div style={{
                        width: "100%", minHeight: "2rem", border: "1px grey solid",
                        borderRadius: ".22rem", padding: ".0875rem"
                    }}>
                        <div style={{ opacity: stagedWord ? "1.0" : "0.33" }}>
                            <HorizontalSortableTiles id={STAGING_TILES_ID} items={currentStaging} />
                        </div>
                    </div>
                    {stagedWord ? `Staged word: ${stagedWord.history[0]}` : "No word staged"}
                </div>
            </Col>
            <Col>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col>
                <SortablePlayerWords
                    id={PLAYER_WORDS_ID}
                    words={currentPuzzle?.gameState.playerWords[0]}
                />
            </Col>
            <Col>
                <SortablePublicTiles
                    id={PUBLIC_TILES_ID}
                    tiles={currentPuzzle?.gameState.tiles}
                />
            </Col>
        </Row>
        <CustomDragOverlay dragData={currentDrag} />
    </DndContext>
}
