import React, { ChangeEvent, useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../app"
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { MultiplayerAction, MultiplayerActionResponse, MultiplayerGame, Word } from "../types";
import { APISocket, logMessage } from "../api/sockets";
import { chatActionBuilder, createNewGame, listGames } from "../api/multiplayerUtil";
import { User, tokenAuth } from "../api/user";
import { decodeJson, encodeJson } from "../api/serialization";
import { RenderGameComponent } from "./multiplayerRendering";
import loadingGif from "../resources/loading.gif"


function ActiveGameComponent(props: {
    gameId: string,
    user: User
}): React.JSX.Element {
    const { gameId, user } = props;
    const [game, setGame] = useState<MultiplayerGame | undefined>(undefined);
    const [gameHash, setGameHash] = useState<number>(0);
    const [error, setError] = useState<string>("");
    const chatScrollRef: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
    const ws = useRef<APISocket | undefined>(undefined);
    const [connectionStatus, setConnectionStatus] = useState<boolean>(false);

    useEffect(() => {
        connect(gameId);
        return () => {
            console.log("Cleaning up");
            const wsCurrent = ws.current;
            if (wsCurrent) wsCurrent.close();
        }
    }, [gameId]);

    const connect = (gameId: string): void => {
        console.log("Connecting to game ", gameId);
        ws.current = new APISocket(
            `/multiplayer/${gameId}`,
            "Multiplayer",
            (_: CloseEvent) => {
                console.log("Multiplayer socket closed.")
                setError("Disconnected.");
                setConnectionStatus(false);
                if (game?.phase !== "FINISHED") {
                    console.log("trying to reconnect")
                    connect(gameId);
                }
            }
        );
        const wsCurrent = ws.current;
        wsCurrent.bind(
            (_) => {
                setConnectionStatus(true);
                wsCurrent.sendString(user.token);
            },
            (event) => {
                const response: MultiplayerActionResponse | null = decodeJson<MultiplayerActionResponse>(event.data);
                if (!response) return;
                if (!response.ok) {
                    setError(response.errorMessage);
                };
                if (!response.game) return;
                setGameHash(response.hashCode);
                setGame(response.game);
            }
        );
    }
    const handleAction = (action: MultiplayerAction): void => {
        const wsCurrent = ws.current;
        if (wsCurrent) wsCurrent.sendString(encodeJson(action));
    }
    const chatScrollToRecent = (): void => {
        const domNode = chatScrollRef.current;
        if (domNode) domNode.scrollTop = 10000000000; //not sure if there's better way to do this
    }

    if (game === undefined) {
        return <div style={{ textAlign: "center" }}>
            <img src={loadingGif} height={64} />
        </div>
    }

    return <RenderGameComponent
        game={game}
        gameHash={gameHash}
        user={user}
        error={error}
        setError={setError}
        handleAction={handleAction}
        chatScrollRef={chatScrollRef}
        chatScrollToRecent={chatScrollToRecent}
    />
}

function MultiplayerGatewayComponent(props: {
    gameId: string | undefined
}): React.JSX.Element {
    const { gameId } = props;
    const [openGames, setOpenGames] = useState<Array<string>>([]);
    const navigate = useNavigate();

    const { user, setUser, reauthenticate } = useContext(UserContext);

    useEffect(() => {
        if (user === null) return;
        reauthenticate();
        return () => { }
    }, [])

    useEffect(() => {
        if (user === null) return;
        if (user.currentGame && (user.currentGame !== gameId)) {
            window.location.assign(`/multiplayer/${user.currentGame}`); //for some reason this glitches out with useNavigate
        }
        if (gameId === undefined) {
            listGames(user, (data: Array<string>) => {
                console.log("found open games")
                setOpenGames(data);
            }, (error: string) => {
                logMessage(error);
            });
        }
    }, [user, gameId])

    const createGame = () => {
        if (user === null) return;
        createNewGame(user,
            (game: MultiplayerGame) => {
                navigate(`/multiplayer/${game.id}`)
            },
            (error) => {
                logMessage(error);
            }
        )
    }

    if (!user)
        return <div>Error: no user</div>
    if (gameId === undefined) {
        if (user.currentGame) {
            return <></>
        } else {
            return <div>
                No game. <Button onClick={createGame}>Create</Button>
                <div>
                    <ul>
                        {openGames.map((value: string, _: number) => {
                            return <li key={value}><a href={`/multiplayer/${value}`}>{value}</a></li>
                        })}
                    </ul>
                </div>
            </div>
        }
    }
    return <ActiveGameComponent gameId={gameId} user={user} />
}

export function MultiplayerComponent(): React.JSX.Element {
    let { gameId } = useParams<{ gameId: string | undefined }>();
    const { user, setUser, reauthenticate } = useContext(UserContext);

    if (user === null) {
        return <div>Please <a href="/login">log in</a></div>
    }
    return <div>
        <MultiplayerGatewayComponent gameId={gameId} />
    </div>
}

